// src/main.js
import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import VueNativeSock from "vue-native-websocket-vue3";
import './assets/main.css';  // Import Tailwind CSS
const app = createApp(App);

app.use(router);
app.use(VueNativeSock, "wss://envmonitorws.neocybex.com:3001", {
    reconnection: true,  // Automatically reconnect if the connection is closed
    reconnectionAttempts: 5,  // Attempt reconnection 5 times
    reconnectionDelay: 3000,  // Wait 3 seconds before retrying
});

app.mount("#app");
