<!-- src/components/RealTime.vue -->
<template>
  <section>
    <div id="main" class="main-content flex-1 bg-gray-100 mt-0 md:mt-2 pb-24 md:pb-5">

      <div class="bg-gray-800 pt-3">
        <div class="rounded-tl-3xl bg-gradient-to-r from-blue-900 to-gray-800 p-4 shadow text-2xl text-white">
          <h1 class="font-bold pl-2">Realtime Sensor's Readings</h1>
        </div>
      </div>

      <div class="flex flex-wrap">

        <div class="w-full md:w-1/2 xl:w-1/3 p-2">
          <!--Metric Card-->
          <div class="bg-gradient-to-b from-orange-200 to-orange-50 border-b-4 border-orange-600 rounded-lg shadow-xl p-5">
            <div class="flex flex-row items-center">
              <div class="flex-shrink pr-4">
                <div class="rounded-full p-2 bg-gray-50 drop-shadow	"><img class="max-w-16 drop-shadow	" src="../assets/thermometer.png" alt=""></div>
              </div>
              <div class="flex-1 text-right md:text-center">
                <h2 class="font-bold uppercase text-gray-600">Temperature</h2>
                <p class="font-bold text-3xl">{{ message?.compTemperature ?? '...........' }} <span class="text-orange-600 "> °C<img class="animate-bounce inline ml-2 hidden" src="../assets/downward-arrow.png" alt=""></span></p>
              </div>
            </div>
          </div>
          <!--/Metric Card-->
        </div>
        <div class="w-full md:w-1/2 xl:w-1/3 p-2">
          <!--Metric Card-->
          <div class="bg-gradient-to-b from-cyan-200 to-cyan-50 border-b-4 border-cyan-600 rounded-lg shadow-xl p-5">
            <div class="flex flex-row items-center">
              <div class="flex-shrink pr-4">
                <div class="rounded-full p-2 bg-gray-50 drop-shadow	"><img class="max-w-16 drop-shadow	" src="../assets/pressure.png" alt=""></div>
              </div>
              <div class="flex-1 text-right md:text-center">
                <h2 class="font-bold uppercase text-gray-600">Pressure</h2>
                <p class="font-bold text-3xl">{{ (message?.pressure / 100)?.toFixed(2) ?? '...........' }} <span class="text-red-500 ">hPa<img class="animate-bounce inline ml-2 hidden" src="../assets/downward-arrow.png" alt=""></span></p>
              </div>
            </div>
          </div>
          <!--/Metric Card-->
        </div>
        <div class="w-full md:w-1/2 xl:w-1/3 p-2">
          <!--Metric Card-->
          <div class="bg-gradient-to-b from-blue-200 to-blue-50 border-b-4 border-blue-600 rounded-lg shadow-xl p-5">
            <div class="flex flex-row items-center">
              <div class="flex-shrink pr-4">
                <div class="rounded-full p-2 bg-gray-50 drop-shadow	"><img class="max-w-16 drop-shadow	" src="../assets/humidity.png" alt=""></div>
              </div>
              <div class="flex-1 text-right md:text-center">
                <h2 class="font-bold uppercase text-gray-600">Humidity</h2>
<!--                <p class="font-bold text-3xl">32 <span class="text-gray-700 ">%<img class="animate-bounce inline ml-2" src="../assets/downward-arrow.png" alt=""></span></p>-->
                <p class="font-bold text-3xl">{{ message?.compHumidity ?? '...........' }} <span class="text-gray-700 "> %</span></p>
              </div>
            </div>
          </div>
          <!--/Metric Card-->
        </div>
        <div class="w-full md:w-1/2 xl:w-1/3 p-2">
          <!--Metric Card-->
          <div class="bg-gradient-to-b from-red-200 to-red-50 border-b-4 border-red-600 rounded-lg shadow-xl p-5">
            <div class="flex flex-row items-center">
              <div class="flex-shrink pr-4">
                <div class="rounded-full p-2 bg-gray-50 drop-shadow	"><img class="max-w-16 drop-shadow	" src="../assets/co2.png" alt=""></div>
              </div>
              <div class="flex-1 text-right md:text-center">
                <h2 class="font-bold uppercase text-gray-600">CO2</h2>
                <p class="font-bold text-3xl">{{ message?.co2Equivalent ?? '...........' }} <span class="text-orange-600 "> ppm<img class="animate-bounce inline ml-2 hidden" src="../assets/downward-arrow.png" alt=""></span> </p>
              </div>
            </div>
          </div>
          <!--/Metric Card-->
        </div>
        <div class="w-full md:w-1/2 xl:w-1/3 p-2">
          <!--Metric Card-->
          <div class="bg-gradient-to-b from-amber-200 to-amber-50 border-b-4 border-amber-600 rounded-lg shadow-xl p-5">
            <div class="flex flex-row items-center">
              <div class="flex-shrink pr-4">
                <div class="rounded-full p-2 bg-gray-50 drop-shadow	"><img class="max-w-16 drop-shadow	" src="../assets/breath.png" alt=""></div>
              </div>
              <div class="flex-1 text-right md:text-center">
                <h2 class="font-bold uppercase text-gray-600">Breath VOC equivalent</h2>
                <p class="font-bold text-3xl">{{ message?.breathVocEquivalent ?? '...........' }}  <span class="text-orange-600 "> ppm<img class="animate-bounce inline ml-2 hidden" src="../assets/downward-arrow.png" alt=""></span></p>
              </div>
            </div>
          </div>
          <!--/Metric Card-->
        </div>
        <div class="w-full md:w-1/2 xl:w-1/3 p-2">
          <!--Metric Card-->
          <div class="bg-gradient-to-b from-green-200 to-green-50 border-b-4 border-green-600 rounded-lg shadow-xl p-5">
            <div class="flex flex-row items-center">
              <div class="flex-shrink pr-4">
                <div class="rounded-full p-2 bg-gray-50 drop-shadow	"><img class="max-w-16 drop-shadow	" src="../assets/indoor-air-quality.png" alt=""></div>
              </div>
              <div class="flex-1 text-right md:text-center">
                <h2 class="font-bold uppercase text-gray-600">IAQ</h2>
                <p class="font-bold text-3xl">{{ message?.iaq ?? '...........' }}  <span class="text-orange-600 "><img class="animate-bounce inline ml-2 hidden" src="../assets/downward-arrow.png" alt=""></span></p>
              </div>
            </div>
          </div>
          <!--/Metric Card-->
        </div>
        <div class="w-full md:w-1/2 xl:w-1/3 p-2">
          <!--Metric Card-->
          <div class="bg-gradient-to-b from-indigo-200 to-indigo-100 border-b-4 border-indigo-600 rounded-lg shadow-xl p-5">
            <div class="flex flex-row items-center">
              <div class="flex-shrink pr-4">
                <div class="rounded-full p-2 bg-gray-50 drop-shadow	"><img class="max-w-16 drop-shadow	" src="../assets/resistor.png" alt=""></div>
              </div>
              <div class="flex-1 text-right md:text-center">
                <h2 class="font-bold uppercase text-gray-600">Gas Resistance</h2>
                <p class="font-bold text-3xl">{{ message?.gasResistance ?? '...........' }}  <span class="text-red-500 "> Ω<img class="animate-bounce inline ml-2 hidden" src="../assets/downward-arrow.png" alt=""></span></p>
              </div>
            </div>
          </div>
          <!--/Metric Card-->
        </div>
        <div class="w-full md:w-1/2 xl:w-1/3 p-2">
          <!--Metric Card-->
          <div class="bg-gradient-to-b from-pink-200 to-pink-50 border-b-4 border-pink-600 rounded-lg shadow-xl p-5">
            <div class="flex flex-row items-center">
              <div class="flex-shrink pr-4">
                <div class="rounded-full p-2 bg-gray-50 drop-shadow	"><img class="max-w-16 drop-shadow	" src="../assets/precipitation.png" alt=""></div>
              </div>
              <div class="flex-1 text-right md:text-center">
                <h2 class="font-bold uppercase text-gray-600">Gas percentage</h2>
                <p class="font-bold text-3xl">{{ message?.gasPercentage ?? '...........' }} <span class="text-gray-700 "> %</span></p>
              </div>
            </div>
          </div>
          <!--/Metric Card-->
        </div>
        <div class="w-full md:w-1/2 xl:w-1/3 p-2">
          <!--Metric Card-->
          <div class="bg-gradient-to-b from-sky-200 to-sky-50 border-b-4 border-sky-600 rounded-lg shadow-xl p-5">
            <div class="flex flex-row items-center">
              <div class="flex-shrink pr-4">
                <div class="rounded-full p-2 bg-gray-50 drop-shadow	"><img class="max-w-16 drop-shadow	" src="../assets/indoor-air-quality.png" alt=""></div>
              </div>
              <div class="flex-1 text-right md:text-center">
                <h2 class="font-bold uppercase text-gray-600">Static IAQ</h2>
                <p class="font-bold text-3xl">{{ message?.staticIaq ?? '...........' }}  <span class="text-orange-600 "><img class="animate-bounce inline ml-2 hidden" src="../assets/downward-arrow.png" alt=""></span></p>
              </div>
            </div>
          </div>
          <!--/Metric Card-->
        </div>
        <div class="w-full md:w-1/2 xl:w-1/3 p-2">
          <!--Metric Card-->
          <div class="bg-gradient-to-b from-yellow-200 to-yellow-50 border-b-4 border-yellow-600 rounded-lg shadow-xl p-5">
            <div class="flex flex-row items-center">
              <div class="flex-shrink pr-4">
                <div class="rounded-full p-2 bg-gray-50 drop-shadow	"><img class="max-w-16 drop-shadow	" src="../assets/thermometer.png" alt=""></div>
              </div>
              <div class="flex-1 text-right md:text-center">
                <h2 class="font-bold uppercase text-gray-600">Raw Temperature</h2>
                <p class="font-bold text-3xl">{{ message?.rawTemperature ?? '...........' }} <span class="text-orange-600 "> °C<img class="animate-bounce inline ml-2 hidden" src="../assets/downward-arrow.png" alt=""></span></p>
              </div>
            </div>
          </div>
          <!--/Metric Card-->
        </div>
        <div class="w-full md:w-1/2 xl:w-1/3 p-2">
          <!--Metric Card-->
          <div class="bg-gradient-to-b from-lime-200 to-lime-50 border-b-4 border-lime-600 rounded-lg shadow-xl p-5">
            <div class="flex flex-row items-center">
              <div class="flex-shrink pr-4">
                <div class="rounded-full p-2 bg-gray-50 drop-shadow	"><img class="max-w-16 drop-shadow	" src="../assets/humidity.png" alt=""></div>
              </div>
              <div class="flex-1 text-right md:text-center">
                <h2 class="font-bold uppercase text-gray-600">Raw relative humidity</h2>
                <p class="font-bold text-3xl">{{ message?.rawHumidity ?? '...........' }} <span class="text-gray-700 "> %</span></p>
              </div>
            </div>
          </div>
          <!--/Metric Card-->
        </div>
        <div class="w-full md:w-1/2 xl:w-1/3 p-2">
          <!--Metric Card-->
          <div class="bg-gradient-to-b from-teal-200 to-teal-50 border-b-4 border-teal-600 rounded-lg shadow-xl p-5">
            <div class="flex flex-row items-center">
              <div class="flex-shrink pr-4">
                <div class="rounded-full p-2 bg-gray-50 drop-shadow	"><img class="max-w-16 drop-shadow	" src="../assets/lidar.png" alt=""></div>
              </div>
              <div class="flex-1 text-right md:text-center">
                <h2 class="font-bold uppercase text-gray-600">Run in status</h2>
                <p class="font-bold text-3xl">{{ message?.runInStatus ?? '...........' }} <span class="text-gray-700 "> </span></p>
              </div>
            </div>
          </div>
          <!--/Metric Card-->
        </div>
        <div class="w-full md:w-1/2 xl:w-1/3 p-2">
          <!--Metric Card-->
          <div class="bg-gradient-to-b from-purple-200 to-purple-50 border-b-4 border-purple-600 rounded-lg shadow-xl p-5">
            <div class="flex flex-row items-center">
              <div class="flex-shrink pr-4">
                <div class="rounded-full p-2 bg-gray-50 drop-shadow	"><img class="max-w-16 drop-shadow	" src="../assets/balance-ball.png" alt=""></div>
              </div>
              <div class="flex-1 text-right md:text-center">
                <h2 class="font-bold uppercase text-gray-600">Stab Status</h2>
                <p class="font-bold text-3xl">{{ message?.stabStatus ?? '...........' }} <span class="text-gray-700 "> </span></p>
              </div>
            </div>
          </div>
          <!--/Metric Card-->
        </div>
        <div class="w-full md:w-1/2 xl:w-1/3 p-2">
          <!--Metric Card-->
          <div class="bg-gradient-to-b from-emerald-200 to-emerald-50 border-b-4 border-emerald-600 rounded-lg shadow-xl p-5">
            <div class="flex flex-row items-center">
              <div class="flex-shrink pr-4">
                <div class="rounded-full p-2 bg-gray-50 drop-shadow	"><img class="max-w-16 drop-shadow	" src="../assets/satisfaction.png" alt=""></div>
              </div>
              <div class="flex-1 text-right md:text-center">
                <h2 class="font-bold uppercase text-gray-600">IAQ accuracy</h2>
                <p class="font-bold text-3xl">{{ message?.iaqAccuracy ?? '...........' }} <span class="text-gray-700 "> </span></p>
              </div>
            </div>
          </div>
          <!--/Metric Card-->
        </div>
        <div class="w-full md:w-1/2 xl:w-1/3 p-2">
          <!--Metric Card-->
          <div class="bg-gradient-to-b from-violet-200 to-violet-50 border-b-4 border-violet-600 rounded-lg shadow-xl p-5">
            <div class="flex flex-row items-center">
              <div class="flex-shrink pr-4">
                <div class="rounded-full p-2 bg-gray-50 drop-shadow	"><img class="max-w-16 drop-shadow	" src="../assets/wifi-router.png" alt=""></div>
              </div>
              <div class="flex-1 text-right md:text-center">
                <h2 class="font-bold uppercase text-gray-600">RSSI</h2>
                <p class="font-bold text-3xl">{{ message?.rssi ?? '...........' }} <span class="text-gray-700 "> </span></p>
              </div>
            </div>
          </div>
          <!--/Metric Card-->
        </div>

      </div>


      <div class="flex flex-row flex-wrap flex-grow mt-2">

        <div class="w-full md:w-1/2 xl:w-1/3 p-6">
          <!--Graph Card-->
<!--          <div id="mainn" style="width: 1200px; height: 1200px "></div>-->
          <!--/Graph Card-->
        </div>

        <div class="w-full md:w-1/2 xl:w-1/3 p-6">
          <!--Graph Card-->

          <!--/Graph Card-->
        </div>

        <div class="w-full md:w-1/2 xl:w-1/3 p-6">
          <!--Graph Card-->

          <!--/Graph Card-->
        </div>

        <div class="w-full md:w-1/2 xl:w-1/3 p-6">
          <!--Graph Card-->

          <!--/Graph Card-->
        </div>

        <div class="w-full md:w-1/2 xl:w-1/3 p-6">
          <!--Table Card-->
          <!--/table Card-->
        </div>

        <div class="w-full md:w-1/2 xl:w-1/3 p-6">
          <!--Advert Card-->

          <!--/Advert Card-->
        </div>


      </div>
    </div>
  </section>
</template>
<script>
export default {
  name: "RealTime",
  data() {
    return {
      message: null, // Initialize as null to store parsed object later
    };
  },
  created() {
    const socket = new WebSocket('wss://envmonitorws.neocybex.com:3001');
    socket.onmessage = (event) => {
      try {
        // Parse the incoming JSON data and store it in `message`
        this.message = JSON.parse(event.data);
        console.log(this.message);
      } catch (error) {
        console.error("Failed to parse WebSocket message:", error);
      }
    };
  },
};
</script>

<style scoped>
/* Additional styles if needed */
</style>
