<!-- src/components/BaseTemplate.vue -->
<template>

  <div class="flex flex-col md:flex-row">
    <nav aria-label="alternative nav">
      <div class="bg-gray-800 shadow-xl h-20 fixed bottom-0 mt-12 md:relative md:h-screen z-10 w-full md:w-48 content-center">

        <div class="md:mt-12 md:w-48 md:fixed md:left-0 md:top-0 content-center md:content-start text-left justify-between">
          <ul class="list-reset flex flex-row md:flex-col pt-3 md:py-3 px-1 md:px-2 text-center md:text-left">
            <li class="mr-3 flex-1">
              <router-link to="/iaq"> <a href="#" class="block py-1 md:py-3 pl-1 align-middle text-white no-underline hover:text-white border-b-2 border-gray-800 hover:border-pink-500">
                <img class="p-0.5 mb-2 mr-2" src="../assets/iaq.png" alt="Girl in a jacket" style="
    width: 40px;
    background: white;
    border-radius: 10px;
        display: inline;
">
                <span class="pb-1 md:pb-0 text-xs md:text-base text-white md:text-white block md:inline-block font-bold">IAQ</span>
              </a></router-link>
            </li>
            <li class="mr-3 flex-1">
              <a href="#" class="block py-1 md:py-3 pl-1 align-middle text-white no-underline hover:text-white border-b-2 border-gray-800 hover:border-purple-500">
                <img class="p-0.5 mb-2 mr-2" src="../assets/analysis.png" alt="Girl in a jacket" style="
    width: 40px;
    background: white;
    border-radius: 10px;
        display: inline;
"><span class="pb-1 md:pb-0 text-xs md:text-base text-white md:text-white block md:inline-block font-bold">History</span>
              </a>
            </li>
            <li class="mr-3 flex-1">
              <router-link to="/realtime"> <a href="" class="block py-1 md:py-3 pl-1 align-middle text-white no-underline hover:text-white border-b-2 border-blue-600">
                <img class="p-0.5 mb-2 mr-2" src="../assets/sound-sensor.png" alt="Girl in a jacket" style="
    width: 40px;
    background: white;
    border-radius: 10px;
        display: inline;
"><span class="pb-1 md:pb-0 text-xs md:text-base text-white md:text-white block md:inline-block font-bold">Sensors</span>
              </a></router-link>
            </li>
          </ul>
        </div>


      </div>
    </nav>
    <router-view />
  </div>

<!--    <main class="flex-grow p-8">-->

<!--    </main>-->

<!--    <footer class="bg-gray-800 text-white text-center py-4">-->
<!--      <p>&copy; 2024 Your Company Name. All rights reserved.</p>-->
<!--    </footer>-->

</template>

<script>
export default {
  name: "BaseTemplate",
};
</script>

<style scoped>
/* If you need to add any specific styles, add here */
</style>
