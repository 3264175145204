// src/router/index.js
import { createRouter, createWebHistory } from 'vue-router';
import BaseTemplate from '../components/BaseTemplate.vue';
import RealTime from '../components/RealTime.vue';
import IAQ from '../components/IAQ.vue';
import Historical from '../components/Historical.vue';

const routes = [
    {
        path: '/',
        component: BaseTemplate,
        children: [
            { path: '', redirect: '/realtime' },  // Redirect root to the realtime page
            { path: 'realtime', component: RealTime },
            { path: 'iaq', component: IAQ },
            { path: 'historical', component: Historical },
        ],
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

export default router;
