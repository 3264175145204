<template>
  <div>
    <h2>Basic ECharts Example</h2>
    <!-- Chart component -->
<!--    <v-chart :options="chartOptions" style="width: 600px; height: 400px;"></v-chart>-->
  </div>
</template>

<!--<script>-->
<!--import { ref, onMounted } from "vue";-->
<!--import VChart from "vue-echarts"; // Import the vue-echarts component-->

<!--// Import ECharts modules-->
<!--import { use } from "echarts/core";-->
<!--import { LineChart } from "echarts/charts";-->
<!--import { GridComponent, TooltipComponent, LegendComponent } from "echarts/components";-->
<!--import { CanvasRenderer } from "echarts/renderers";-->

<!--// Register the necessary components-->
<!--use([LineChart, GridComponent, TooltipComponent, CanvasRenderer]);-->

<!--export default {-->
<!--  components: {-->
<!--    VChart, // Register the VChart component-->
<!--  },-->
<!--  setup() {-->
<!--    // Chart options-->
<!--    const chartOptions = ref({-->
<!--      title: {-->
<!--        text: "Sample Static Data Chart",-->
<!--      },-->
<!--      tooltip: {-->
<!--        trigger: "axis",-->
<!--      },-->
<!--      xAxis: {-->
<!--        type: "category",-->
<!--        data: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],-->
<!--      },-->
<!--      yAxis: {-->
<!--        type: "value",-->
<!--      },-->
<!--      series: [-->
<!--        {-->
<!--          name: "Pressure",-->
<!--          type: "line",-->
<!--          data: [120, 200, 150, 80, 70, 110, 130], // Static data for testing-->
<!--        },-->
<!--      ],-->
<!--    });-->

<!--    return {-->
<!--      chartOptions,-->
<!--    };-->
<!--  },-->
<!--};-->
<!--</script>-->

<style scoped>
/* Optional CSS for your chart */
</style>
