<!-- src/components/RealTime.vue -->
<template>
  <section>
    <div id="main" class="main-content flex-1 bg-gray-100 mt-0 md:mt-2 pb-24 md:pb-5s">

      <div class="bg-gray-800 pt-3">
        <div class="rounded-tl-3xl bg-gradient-to-r from-blue-900 to-gray-800 p-4 shadow text-2xl text-white">
          <h1 class="font-bold pl-2">Realtime Sensor's Readings</h1>
        </div>
      </div>

      <div class="flex flex-row flex-wrap flex-grow">
        <div class="w-full md:w-1/2 xl:w-1/2 p-2">
          <!--Advert Card-->
          <div class="bg-white border-transparent rounded-lg shadow-xl">
            <div class="bg-gradient-to-b from-gray-300 to-gray-100 uppercase text-gray-800 border-b-2 border-gray-300 rounded-tl-lg rounded-tr-lg p-2">
              <h2 class="font-bold uppercase text-gray-600">Indoor Air Quality Index</h2>
            </div>
            <div class="p-0 text-center">

              <div id="iaq" class="lg:w-[65vh] h-[50vh] sm:h-[80vh] lg:h-[65vh]"></div>
<!--<p style="float: right">hh</p>-->


            </div>
          </div>
          <!--/Advert Card-->
        </div>
        <div class="w-full md:w-1/2 xl:w-1/2 p-2">
          <!--Advert Card-->
          <div class="bg-white border-transparent rounded-lg shadow-xl">
            <div class="bg-gradient-to-b from-gray-300 to-gray-100 uppercase text-gray-800 border-b-2 border-gray-300 rounded-tl-lg rounded-tr-lg p-2">
              <h2 class="font-bold uppercase text-gray-600">Equivalent Carbon Dioxide</h2>
            </div>
            <div class="p-0 text-center">

              <div id="co2" class="lg:w-[65vh] h-[50vh] sm:h-[80vh] lg:h-[65vh]"></div>
<!--<p style="float: right">hh</p>-->


            </div>
          </div>
          <!--/Advert Card-->
        </div>
        <div class="w-full md:w-1/2 xl:w-1/2 p-2">
          <!--Advert Card-->
          <div class="bg-white border-transparent rounded-lg shadow-xl">
            <div class="bg-gradient-to-b from-gray-300 to-gray-100 uppercase text-gray-800 border-b-2 border-gray-300 rounded-tl-lg rounded-tr-lg p-2">
              <h2 class="font-bold uppercase text-gray-600">Air Quality Sensor Accuracy</h2>
            </div>
            <div class="p-0 text-center">

              <div id="iaq-accuracy" class="lg:w-[65vh] h-[50vh] sm:h-[80vh] lg:h-[65vh]"></div>
<!--<p style="float: right">hh</p>-->


            </div>
          </div>
          <!--/Advert Card-->
        </div>
        <div class="w-full md:w-1/2 xl:w-1/2 p-2">
          <!--Advert Card-->
          <div class="bg-white border-transparent rounded-lg shadow-xl">
            <div class="bg-gradient-to-b from-gray-300 to-gray-100 uppercase text-gray-800 border-b-2 border-gray-300 rounded-tl-lg rounded-tr-lg p-2">
              <h2 class="font-bold uppercase text-gray-600">Air Quality Sensor Accuracy</h2>
            </div>
            <div class="p-0 text-center">

              <div id="iaq-accuracy" class="lg:w-[65vh] h-[50vh] sm:h-[80vh] lg:h-[65vh]"></div>
<!--<p style="float: right">hh</p>-->


            </div>
          </div>
          <!--/Advert Card-->
        </div>
        <div class="w-full md:w-1/2 xl:w-1/2 p-2">
          <!--Advert Card-->
          <div class="bg-white border-transparent rounded-lg shadow-xl">
            <div class="bg-gradient-to-b from-gray-300 to-gray-100 uppercase text-gray-800 border-b-2 border-gray-300 rounded-tl-lg rounded-tr-lg p-2">
              <h2 class="font-bold uppercase text-gray-600">Air Quality Sensor Accuracy</h2>
            </div>
            <div class="p-0 text-center">

              <div id="iaq-accuracy" class="lg:w-[65vh] h-[50vh] sm:h-[80vh] lg:h-[65vh]"></div>
<!--<p style="float: right">hh</p>-->


            </div>
          </div>
          <!--/Advert Card-->
        </div>
        <!-- Add other sections if needed -->
      </div>
    </div>
  </section>
</template>

<!--<script>-->
<!--import * as echarts from 'echarts';-->

<!--export default {-->
<!--  mounted() {-->
<!--    var iaqa = document.getElementById('iaq-accuracy');-->
<!--    var myCharta = echarts.init(iaqa);-->
<!--    var option2;-->

<!--    const gaugeData = [-->
<!--      {-->
<!--        value: 50,-->
<!--        name: 'Static IAQ',-->
<!--        title: {-->
<!--          offsetCenter: ['0%', '-30%']-->
<!--        },-->
<!--        detail: {-->
<!--          valueAnimation: true,-->
<!--          offsetCenter: ['0%', '-20%']-->
<!--        }-->
<!--      },-->
<!--      {-->
<!--        value: 40,-->
<!--        name: 'IAQ',-->
<!--        title: {-->
<!--          offsetCenter: ['0%', '0%']-->
<!--        },-->
<!--        detail: {-->
<!--          valueAnimation: true,-->
<!--          offsetCenter: ['0%', '10%']-->
<!--        }-->
<!--      },-->
<!--      {-->
<!--        value: 60,-->
<!--        name: 'Accuracy',-->
<!--        title: {-->
<!--          offsetCenter: ['0%', '30%']-->
<!--        },-->
<!--        detail: {-->
<!--          valueAnimation: true,-->
<!--          offsetCenter: ['0%', '40%']-->
<!--        }-->
<!--      }-->
<!--    ];-->
<!--    option2 = {-->
<!--      series: [-->
<!--        {-->
<!--          type: 'gauge',-->
<!--          startAngle: 90,-->
<!--          endAngle: -270,-->
<!--          pointer: {-->
<!--            show: false-->
<!--          },-->
<!--          progress: {-->
<!--            show: true,-->
<!--            overlap: false,-->
<!--            roundCap: true,-->
<!--            clip: false,-->
<!--            itemStyle: {-->
<!--              borderWidth: 1,-->
<!--              borderColor: '#464646'-->
<!--            }-->
<!--          },-->
<!--          axisLine: {-->
<!--            lineStyle: {-->
<!--              width: 40-->
<!--            }-->
<!--          },-->
<!--          splitLine: {-->
<!--            show: false,-->
<!--            distance: 0,-->
<!--            length: 10-->
<!--          },-->
<!--          axisTick: {-->
<!--            show: false-->
<!--          },-->
<!--          axisLabel: {-->
<!--            show: false,-->
<!--            distance: 50-->
<!--          },-->
<!--          data: gaugeData,-->
<!--          title: {-->
<!--            fontSize: 14-->
<!--          },-->
<!--          detail: {-->
<!--            width: 50,-->
<!--            height: 14,-->
<!--            fontSize: 14,-->
<!--            color: 'inherit',-->
<!--            borderColor: 'inherit',-->
<!--            borderRadius: 20,-->
<!--            borderWidth: 1,-->
<!--            formatter: '{value}%'-->
<!--          }-->
<!--        }-->
<!--      ]-->
<!--    };-->
<!--    setInterval(function () {-->
<!--      // gaugeData[0].value = 90;-->
<!--      gaugeData[1].value = +(Math.random() * 100).toFixed(2);-->
<!--      gaugeData[2].value = +(Math.random() * 100).toFixed(2);-->
<!--      myCharta.setOption({-->
<!--        series: [-->
<!--          {-->
<!--            data: gaugeData,-->
<!--            pointer: {-->
<!--              show: false-->
<!--            }-->
<!--          }-->
<!--        ]-->
<!--      });-->
<!--    }, 2000);-->

<!--    option2 && myCharta.setOption(option2);-->

<!--//AQI-->

<!--    function autoFontSize() {-->
<!--      let width = document.getElementById('iaq').offsetWidth;-->
<!--      let newFontSize = Math.round(width / 20);-->
<!--      console.log(`Current width : ${width}, Updating Fontsize to ${newFontSize}`);-->
<!--      return newFontSize;-->
<!--    };-->
<!--    const chartDom = document.getElementById('iaq');-->
<!--    const myChart = echarts.init(chartDom, );-->

<!--    const option = {-->
<!--      series: [-->
<!--        {-->
<!--          type: 'gauge',-->
<!--          startAngle: 180,-->
<!--          radius: '70%', // Make it more responsive-->
<!--          min: 0,-->
<!--          max: 500,-->
<!--          splitNumber: 5,-->
<!--          center: ['50%', '60%'],-->
<!--          axisLine: {-->
<!--            lineStyle: {-->
<!--              width: 6,-->
<!--              color: [-->
<!--                [0.1, '#7CFFB2'], // Good (Green)-->
<!--                [0.3, '#FDDD60'], // Moderate (Yellow)-->
<!--                [0.5, '#FFAA00'], // Unhealthy for Sensitive Groups (Orange)-->
<!--                [0.7, '#FF6E76'], // Unhealthy (Red)-->
<!--                [1.5, '#900020']  // Hazardous (Maroon)-->
<!--              ]-->
<!--            }-->
<!--          },-->
<!--          pointer: {-->
<!--            icon: 'path://M12.8,0.7l12,40.1H0.7L12.8,0.7z',-->
<!--            length: '12%',-->
<!--            width: 20,-->
<!--            offsetCenter: [0, '-60%'],-->
<!--            itemStyle: {-->
<!--              color: 'auto'-->
<!--            }-->
<!--          },-->
<!--          axisTick: {-->
<!--            length: 12,-->
<!--            lineStyle: {-->
<!--              color: 'auto',-->
<!--              width: 2-->
<!--            }-->
<!--          },-->
<!--          splitLine: {-->
<!--            length: 20,-->
<!--            lineStyle: {-->
<!--              color: 'auto',-->
<!--              width: 5-->
<!--            }-->
<!--          },-->
<!--          axisLabel: {-->
<!--            color: '#000000',-->
<!--            fontSize: 14,-->
<!--            distance: -50,-->
<!--            rotate: 'tangential',-->
<!--            formatter: function (value) {-->
<!--              if (value === 0) {-->
<!--                return 'Good';-->
<!--              } else if (value === 100) {-->
<!--                return 'Moderate';-->
<!--              } else if (value === 150) {-->
<!--                return 'Unhealthy for Sensitive';-->
<!--              } else if (value === 200) {-->
<!--                return 'Unhealthy';-->
<!--              } else if (value === 300) {-->
<!--                return 'Very Unhealthy';-->
<!--              } else if (value === 400) {-->
<!--                return 'Hazardous';-->
<!--              }-->
<!--              return '';-->
<!--            }-->
<!--          },-->
<!--          title: {-->
<!--            offsetCenter: [0, '10%'],-->
<!--            fontSize: autoFontSize()-->
<!--          },-->
<!--          detail: {-->
<!--            fontSize: autoFontSize(),-->
<!--            offsetCenter: ['0%', '-15%'],-->
<!--            valueAnimation: true,-->
<!--            formatter: function (value) {-->
<!--              return value.toFixed(0) + ' AQI';-->
<!--            },-->
<!--            color: 'inherit'-->
<!--          },-->
<!--          data: [-->
<!--            {-->
<!--              value: 51, // Example AQI value-->
<!--              name: 'AQI'-->
<!--            }-->
<!--          ]-->
<!--        }-->
<!--      ]-->
<!--    };-->
<!--    setInterval(function () {-->
<!--      myChart.setOption({-->
<!--        series: [-->
<!--          {-->
<!--            data: [-->
<!--              {-->
<!--                value: +(Math.random() * 1000).toFixed(2)-->
<!--              }-->
<!--            ]-->
<!--          }-->
<!--        ]-->
<!--      });-->
<!--    }, 2000);-->
<!--    myChart.setOption(option);-->

<!--    // Make chart responsive-->
<!--    window.addEventListener('resize', () => {-->
<!--      myChart.resize({  width: 'auto',-->
<!--        height: 'auto'});-->
<!--    });-->


<!--    //CO2-->


<!--    const co2chartDom = document.getElementById('co2');-->
<!--    const co2myChart = echarts.init(co2chartDom, );-->

<!--    const co2option = {-->
<!--      series: [-->
<!--        {-->
<!--          type: 'gauge',-->
<!--          startAngle: 180,-->
<!--          radius: '70%', // Make it more responsive-->
<!--          min: 0,-->
<!--          max: 500,-->
<!--          splitNumber: 5,-->
<!--          center: ['50%', '60%'],-->
<!--          axisLine: {-->
<!--            lineStyle: {-->
<!--              width: 6,-->
<!--              color: [-->
<!--                [0.1, '#7CFFB2'], // Good (Green)-->
<!--                [0.3, '#FDDD60'], // Moderate (Yellow)-->
<!--                [0.5, '#FFAA00'], // Unhealthy for Sensitive Groups (Orange)-->
<!--                [0.7, '#FF6E76'], // Unhealthy (Red)-->
<!--                [1.5, '#900020']  // Hazardous (Maroon)-->
<!--              ]-->
<!--            }-->
<!--          },-->
<!--          pointer: {-->
<!--            icon: 'path://M12.8,0.7l12,40.1H0.7L12.8,0.7z',-->
<!--            length: '12%',-->
<!--            width: 20,-->
<!--            offsetCenter: [0, '-60%'],-->
<!--            itemStyle: {-->
<!--              color: 'auto'-->
<!--            }-->
<!--          },-->
<!--          axisTick: {-->
<!--            length: 12,-->
<!--            lineStyle: {-->
<!--              color: 'auto',-->
<!--              width: 2-->
<!--            }-->
<!--          },-->
<!--          splitLine: {-->
<!--            length: 20,-->
<!--            lineStyle: {-->
<!--              color: 'auto',-->
<!--              width: 5-->
<!--            }-->
<!--          },-->
<!--          axisLabel: {-->
<!--            color: '#000000',-->
<!--            fontSize: 14,-->
<!--            distance: -50,-->
<!--            rotate: 'tangential',-->
<!--            formatter: function (value) {-->
<!--              if (value === 0) {-->
<!--                return 'Good';-->
<!--              } else if (value === 100) {-->
<!--                return 'Moderate';-->
<!--              } else if (value === 150) {-->
<!--                return 'Unhealthy for Sensitive';-->
<!--              } else if (value === 200) {-->
<!--                return 'Unhealthy';-->
<!--              } else if (value === 300) {-->
<!--                return 'Very Unhealthy';-->
<!--              } else if (value === 400) {-->
<!--                return 'Hazardous';-->
<!--              }-->
<!--              return '';-->
<!--            }-->
<!--          },-->
<!--          title: {-->
<!--            offsetCenter: [0, '10%'],-->
<!--            fontSize: autoFontSize()-->
<!--          },-->
<!--          detail: {-->
<!--            fontSize: autoFontSize(),-->
<!--            offsetCenter: ['0%', '-15%'],-->
<!--            valueAnimation: true,-->
<!--            formatter: function (value) {-->
<!--              return value.toFixed(0) + ' PPM';-->
<!--            },-->
<!--            color: 'inherit'-->
<!--          },-->
<!--          data: [-->
<!--            {-->
<!--              value: 51, // Example AQI value-->
<!--              name: 'ppm'-->
<!--            }-->
<!--          ]-->
<!--        }-->
<!--      ]-->
<!--    };-->
<!--    setInterval(function () {-->
<!--      co2myChart.setOption({-->
<!--        series: [-->
<!--          {-->
<!--            data: [-->
<!--              {-->
<!--                value: (Math.random() * 1000).toFixed(2)-->
<!--              }-->
<!--            ]-->
<!--          }-->
<!--        ]-->
<!--      });-->
<!--    }, 2000);-->
<!--    co2myChart.setOption(co2option);-->

<!--    // Make chart responsive-->
<!--    window.addEventListener('resize', () => {-->
<!--      co2myChart.resize({  width: 'auto',-->
<!--        height: 'auto'});-->
<!--    });-->


<!--  }-->
<!--};-->

<!--</script>-->

<style scoped>

</style>
