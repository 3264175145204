<!-- src/App.vue -->
<template>

<!--  <body id="app" class="bg-gray-800 font-sans leading-normal tracking-normal">-->

<!--  <div >-->
    <router-view />
<!--  </div>-->
<!--  </body>-->
</template>

<script>
export default {
  name: "App",
};
</script>
